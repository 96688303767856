import i18n from '@/language'
import moment from 'moment'

export function required(value) {
  return !!value || value === false || i18n.t('campo_obrigatorio')
}

export function dateShouldBeAfter(curDate, date) {
  if (date) {
    return (
      moment(curDate).isAfter(date) ||
      `${i18n.t('date_should_be_after')} ${moment(date).format('DD/MM/YYYY')}`
    )
  }
  return i18n.t('set_start_date')
}

export function maxValue(curValue, total, warning) {
  return total - curValue >= 0 ? true : warning
}
